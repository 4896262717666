:root{

    --main-bg-color:#fff;
    --main-text-color:#000;

    --btn-primary-bg-color:#041b37;
    --btn--primary-color:#fff;

    --btn-secondary-bg-color:#f4f4f4;
    --btn-secondary-color:#041b37;

    --qna-rooms-list-color: #ffffff;
    --qna-rooms-list-bg: #041b37;
    --qna-btn-primary-bg: #041b37;
    --qna-btn-primary-color: #ffffff;
    --qna-btn-secondary-bg: #f4f4f4;
    --qna-btn-secondary-color: #041b37;
    --qna-comments-color: #111111;
    --qna-comments-bg: #ceebff;
}
body{
    color: #000;
    background: var(--main-bg-color);
}

.qna-v2-main-container{
    /*qna colors*/
    --jme-btn-primary-bg: var(--qna-btn-primary-bg);
    --jme-btn-primary-color: var(--qna-btn-primary-color);

    --jme-qna-btn-primary-bg: var(--qna-btn-primary-bg);
    --jme-qna-btn-primary-color: var(--qna-btn-primary-color);

    --jme-btn-secondary-bg : var(--qna-btn-secondary-bg);
    --jme-btn-secondary-color: var(--qna-btn-secondary-color);

    .jme-voting-widget__vote-now{
        background-color:var(--qna-btn-primary-bg);
        color: var(--qna-btn-primary-color);
        font-weight: bold;
    }
    .jme-qna-widget .card-body{
        padding: .5rem;
    }


}
.jme-qna-disabled{
    opacity: .57 !important;
    pointer-events: none !important;
}